import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const Proyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // Estado para el orden
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const { user } = useUsuario(); // Obtener el usuario desde el contexto

  useEffect(() => {
    const fetchProyectos = async () => {
      try {
        // Obtener los clientes a los que el usuario tiene acceso
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('cliente_rut')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const clienteRuts = colaboradoresData.map(colaborador => colaborador.cliente_rut);

        // Obtener los proyectos de los clientes a los que el usuario tiene acceso
        const { data: proyectosData, error: proyectosError } = await supabase
          .from('proyectos')
          .select('*')
          .in('cliente_rut', clienteRuts);

        if (proyectosError) {
          setError(proyectosError.message);
          setLoading(false);
          return;
        }

        const promises = proyectosData.map(async (proyecto) => {
          const { data: clienteData, error: clienteError } = await supabase
            .from('clientes')
            .select('nombre, celular')
            .eq('rut', proyecto.cliente_rut)
            .single();

          if (clienteError) {
            throw clienteError;
          }

          const { data: reportesData, error: reportesError } = await supabase
            .from('reportes')
            .select('id')
            .eq('proyecto_key', proyecto.key);

          if (reportesError) {
            throw reportesError;
          }

          return {
            ...proyecto,
            cliente: clienteData,
            reportCount: reportesData.length, // Asegurando que reportesData sea un array
          };
        });

        const proyectosWithClientes = await Promise.all(promises);

        setProyectos(proyectosWithClientes);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProyectos();
  }, [user.id]);

  const sortedProyectos = [...proyectos].sort((a, b) => {
    return sortOrder === 'asc' ? a.reportCount - b.reportCount : b.reportCount - a.reportCount;
  });

  const filteredProyectos = sortedProyectos.filter((proyecto) => {
    return (
      proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proyecto.cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Lista de Proyectos</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar por proyecto o cliente"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Ordenar por cantidad de reportes ({sortOrder === 'asc' ? 'Ascendente' : 'Descendente'})
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProyectos.map((proyecto) => (
          <div key={proyecto.id} className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">
              <Link to={`/cliente/${proyecto.cliente_rut}`} className="text-blue-500 hover:underline">
                {proyecto.proyecto}
              </Link>
            </h2>
            <p className="mb-2">
              <Link to={`/cliente/${proyecto.cliente_rut}`} className="text-blue-500 hover:underline">
                {proyecto.cliente.nombre}
              </Link>
            </p>
            <p className="mb-2">Celular: {proyecto.cliente.celular}</p>
            <p className="mb-2">Cantidad de Reportes: {proyecto.reportCount}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Proyectos;
