import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import './LoginForm.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const [redirectTo, setRedirectTo] = useState(() => {
    // return location.state?.from || '/';
  // });

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const { error: loginError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (loginError) {
        console.error('Error de Supabase Auth:', loginError);
        setError(`Error de autenticación: ${traducirError(loginError.message)}`);
      } else {
        navigate(location.state?.from || '/');
      }
    } catch (error) {
      console.error('Error en el proceso de inicio de sesión:', error);
      setError('Error al iniciar sesión. Por favor, intenta de nuevo más tarde.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const traducirError = (mensaje) => {
    switch (mensaje) {
      case 'Invalid login credentials':
        return 'Credenciales de inicio de sesión inválidas';
      case 'User not confirmed':
        return 'Usuario no confirmado';
      // Agrega más casos según los mensajes de error que puedas recibir
      default:
        return mensaje;
    }
  };

  const isMobile = window.innerWidth <= 768;
  const backgroundImage = isMobile
    ? '/fondos/ingresar-tubim-cel-fondo2.png'
    : '/fondos/ingresar-tubim-fondo2.png';

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 relative overflow-hidden glass-effect">
        <div className="absolute inset-0 glass-background opacity-25 blur-md"></div>
        <div className="relative z-10">
          <h2 className="title-mm color-mm text-3xl font-bold mb-6 text-center">BIENVENIDO</h2>
          <form onSubmit={handleLogin}>
            <div>
              <input
                id="email"
                type="email"
                className="appearance-none border rounded w-full mt-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                id="password"
                type="password"
                className="border rounded w-full mt-6 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
            <button
              type="submit"
              className="btn-ghost-mm font-bold mt-20 py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              disabled={isSubmitting}
            >
              Ingresar
            </button>
          </form>
        </div>
        <div className="absolute inset-0 border-4 border-mm rounded transition duration-500 ease-in-out"></div>
      </div>
    </div>
  );
};

export default LoginForm;
