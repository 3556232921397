import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom'; // Importa useNavigate
import { supabase } from '../../lib/supabaseClient';

const SubirArchivo = ({ onUploadSuccess }) => {
  const { reporteKey } = useParams();
  const navigate = useNavigate(); // Inicializa useNavigate

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Por favor, selecciona un archivo.');
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(null);

    console.log("Archivo seleccionado:", file);
    const fileExt = file.name.split('.').pop();
    const fileName = `${reporteKey}/${Date.now()}.${fileExt}`;
    const filePath = `${fileName}`;
    console.log("Ruta del archivo:", filePath);

    try {
      console.log("Intentando subir el archivo...");
      let { error: uploadError } = await supabase.storage
        .from('archivos')
        .upload(filePath, file);

        const publicURL = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/archivos/${filePath}`;

      if (uploadError) {
        console.error("Error al subir el archivo:", uploadError.message);
        setError(uploadError.message);
        setUploading(false);
        return;
      }

      console.log("publicURL", publicURL);
      console.log("reporteKey", reporteKey);
      console.log("file", file);
      console.log("fileExt", fileExt);

      console.log("Intentando insertar en la base de datos...");
      const response = await supabase
        .from('archivos')
        .insert([
          { 
            archivo: file.name, 
            extension: fileExt, 
            url: publicURL, 
            reporte_key: reporteKey 
          }
        ]);

      console.log("Respuesta de la inserción en la base de datos:", response);

      if (response.error) {
        setError('Error guardando la información en la base de datos.');
      } else {
        setSuccess('Archivo subido exitosamente.');
        onUploadSuccess(); // Llama a la función de éxito
        navigate(`/reporte/${reporteKey}`); // Redirige al reporte
      }

    } catch (error) {
      console.error("Error en el proceso de subida:", error.message);
      setError('Error subiendo el archivo.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Subir Archivo</h2>
      <input type="file" onChange={handleFileChange} />
      <button
        className="bg-blue-500 text-white px-4 py-2 mt-4"
        onClick={handleUpload}
        disabled={uploading}
      >
        {uploading ? 'Subiendo...' : 'Subir Archivo'}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {success && <div className="text-green-500 mt-2">{success}</div>}
    </div>
  );
};

// Agrega PropTypes para validar las props
SubirArchivo.propTypes = {
  onUploadSuccess: PropTypes.func.isRequired,
};

export default SubirArchivo;