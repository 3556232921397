import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from './../../lib/supabaseClient';

const EditarCliente = () => {
  const { clienteRut } = useParams();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState({
    nombre: '',
    rut: '',
    direccion: '',
    celular: '',
    email: '' // Añadir email al estado
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCliente = async () => {
      const { data, error } = await supabase
        .from('clientes')
        .select('*')
        .eq('rut', clienteRut)
        .single();

      if (error) {
        setError(error.message);
      } else {
        setCliente(data);
      }
      setLoading(false);
    };

    fetchCliente();
  }, [clienteRut]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCliente((prevCliente) => ({
      ...prevCliente,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await supabase
      .from('clientes')
      .update(cliente)
      .eq('rut', clienteRut);

    if (error) {
      setError(error.message);
    } else {
      navigate(`/cliente/${clienteRut}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Editar Cliente</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Nombre</label>
          <input
            type="text"
            name="nombre"
            value={cliente.nombre}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">RUT</label>
          <input
            type="text"
            name="rut"
            value={cliente.rut}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            disabled
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="text"
            name="email"
            value={cliente.email}
            className="w-full px-3 py-2 border rounded"
            disabled // Deshabilitar el campo de email
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Dirección</label>
          <input
            type="text"
            name="direccion"
            value={cliente.direccion}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Celular</label>
          <input
            type="text"
            name="celular"
            value={cliente.celular}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Guardar
        </button>
      </form>
    </div>
  );
};

export default EditarCliente;