/* eslint-disable */
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
// import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import MyAccount from './components/MyAccount';
import Clientes from './components/clientes/Clientes';
import Cliente from './components/clientes/Cliente';
import CrearCliente from './components/clientes/CrearCliente';
import EditarCliente from './components/clientes/EditarCliente';
import Proyecto from './components/proyectos/Proyecto';
import Proyectos from './components/proyectos/Proyectos';
import CrearProyecto from './components/proyectos/CrearProyecto';
import Reportes from './components/reportes/Reportes';
import Reporte from './components/reportes/Reporte';
import CrearReporte from './components/reportes/CrearReporte';
import CrearColaborador from './components/colaboradores/CrearColaborador';
import AceptarInvitacion from './components/colaboradores/AceptarInvitacion';
import SubirArchivo from './components/reportes/SubirArchivo';

function App() {

	return (
		<div className="App">
			<Router basename={process.env.PUBLIC_URL}>
          <Header />
				<Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/registrate" element={<RegisterForm />} /> */}
            <Route path="/ingresar" element={<LoginForm />} />
            <Route path="/mis-datos" element={<MyAccount />} />
            <Route path="/crear-cliente" element={<CrearCliente />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/cliente/:clienteRut" element={<Cliente />} />
            <Route path="/cliente/:clienteRut/editar" element={<EditarCliente />} />
            <Route path="/cliente/:clienteRut/crear-proyecto" element={<CrearProyecto />} />
            <Route path="/proyectos" element={<Proyectos />} />
            <Route path="/cliente/:clienteRut/proyecto/:proyectoKey" element={<Proyecto />} />
            <Route path="/reportes" element={<Reportes />} />
            <Route path="/cliente/:clienteRut/proyecto/:proyectoKey/crear-reporte" element={<CrearReporte />} />
            <Route path="/cliente/:clienteRut/proyecto/:proyectoKey/reporte/:reporteKey" element={<Reporte />} />
            <Route path="/cliente/:clienteRut/proyecto/:proyectoKey/reporte/:reporteKey/subir-archivo" element={<SubirArchivo />} />
            <Route path="/cliente/:clienteRut/crear-colaborador" element={<CrearColaborador />} /> {/* Nueva ruta */}
            <Route path="/aceptar-invitacion" element={<AceptarInvitacion />} />
            {/* Otras rutas */}
				</Routes>
          <Footer />
			</Router>
		</div>
	);
}

function Home() {
  return (
    <main>
    </main>
  );
}

export default App;
/* eslint-enable */
