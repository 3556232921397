// Clientes.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('proyectos_asc'); // Nuevo estado para el orden
  const [searchQuery, setSearchQuery] = useState(''); // Nuevo estado para el buscador
  const navigate = useNavigate();
  const { user } = useUsuario(); // Obtener el usuario desde el contexto

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        // Obtener los clientes a los que el usuario tiene acceso
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('cliente_rut')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const clienteRuts = colaboradoresData.map(colaborador => colaborador.cliente_rut);

        const { data: clientesData, error: clientesError } = await supabase
          .from('clientes')
          .select('*, proyectos (id)') // Asegúrate de incluir la relación con proyectos
          .in('rut', clienteRuts);

        if (clientesError) {
          setError(clientesError.message);
          setLoading(false);
          return;
        }

        // Contar el número de proyectos para cada cliente
        const clientesConProyectos = clientesData.map(cliente => ({
          ...cliente,
          num_proyectos: cliente.proyectos ? cliente.proyectos.length : 0,
        }));

        setClientes(clientesConProyectos);
      } catch (error) {
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClientes();
  }, [user.id]);

  const sortClientes = (clientes, order) => {
    switch (order) {
      case 'proyectos_asc':
        return [...clientes].sort((a, b) => a.num_proyectos - b.num_proyectos);
      case 'proyectos_desc':
        return [...clientes].sort((a, b) => b.num_proyectos - a.num_proyectos);
      default:
        return clientes;
    }
  };

  const filterClientes = (clientes, query) => {
    if (!query) return clientes;
    return clientes.filter(cliente => {
      const nombre = cliente.nombre ? cliente.nombre.toLowerCase() : '';
      const direccion = cliente.direccion ? cliente.direccion.toLowerCase() : '';
      const rut = cliente.rut ? cliente.rut.toLowerCase() : '';
      const celular = cliente.celular ? cliente.celular.toLowerCase() : '';
      const email = cliente.email ? cliente.email.toLowerCase() : ''; // Nuevo campo para el email
      return (
        nombre.includes(query.toLowerCase()) ||
        direccion.includes(query.toLowerCase()) ||
        rut.includes(query.toLowerCase()) ||
        celular.includes(query.toLowerCase()) ||
        email.includes(query.toLowerCase()) // Búsqueda por email
      );
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleClientClick = (clienteRut) => {
    navigate(`/cliente/${clienteRut}`);
  };

  const sortedClientes = sortClientes(clientes, sortOrder);
  const filteredClientes = filterClientes(sortedClientes, searchQuery);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">Clientes</h1>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded ml-auto"
          onClick={() => navigate('/crear-cliente')}
        >
          Crear Nuevo Cliente
        </button>
      </div>
      <div className="flex justify-between mb-4">
        <input
          type="text"
          className="bg-gray-200 p-2 rounded"
          placeholder="Buscar..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          className="bg-gray-200 p-2 rounded"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="proyectos_asc">Cantidad de Proyectos Ascendente</option>
          <option value="proyectos_desc">Cantidad de Proyectos Descendente</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredClientes.map((cliente) => (
          <div key={cliente.id} className="card bg-base-100 w-96 shadow-xl">
            <figure>
              <img src={cliente.logo} alt="Logo" className="w-12 h-12 object-contain" />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{cliente.nombre}</h2>
              <p>RUT: {cliente.rut}</p>
              <p>Dirección: {cliente.direccion}</p>
              <p>Celular: {cliente.celular}</p>
              <p>N° de Proyectos: {cliente.num_proyectos}</p>
              <div className="card-actions justify-end">
                <button
                  className="btn btn-primary"
                  onClick={() => handleClientClick(cliente.rut)}
                >
                  Ver Detalles
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clientes;
