import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const CrearCliente = () => {
  const [rut, setRut] = useState('');
  const [nombre, setNombre] = useState(''); // Nuevo estado para el nombre
  const navigate = useNavigate();
  const { usuario, loading: usuarioLoading } = useUsuario(); // Obtener el usuario del contexto

  const handleRutChange = (e) => {
    let value = e.target.value.replace(/[^0-9kK]/g, ''); // Elimina caracteres no permitidos
    if (value.length > 1) {
      value = value.slice(0, -1) + '-' + value.slice(-1); // Añade el guion antes del último dígito
    }
    setRut(value);
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value); // Manejador de cambio para el nombre
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (usuarioLoading) return; // Esperar a que el usuario esté cargado

    try {
      // Inserta el nuevo cliente en la base de datos usando Supabase
      const { data: cliente, error: clienteError } = await supabase
        .from('clientes')
        .insert([{ rut, nombre }]) // Eliminar email
        .select()
        .single();

      if (clienteError) throw clienteError;

      // Inserta el registro en la tabla colaboradores

      console.log('Usuario:', usuario);
      console.log('cliente.rut:', cliente.rut);
      console.log('cliente.rut:', cliente.rut);

      const { data: colaborador, error: colaboradorError } = await supabase
        .from('colaboradores')
        .insert([{ cliente_rut: cliente.rut, usuario_id: usuario.id, perfil: 'admin' }]);

      if (colaboradorError) throw colaboradorError;

      console.log('Nuevo Cliente:', cliente);
      console.log('Colaborador asignado:', colaborador);

      if (cliente) {
        navigate(`/cliente/${cliente.rut}`); // Redirige al cliente creado
      }
    } catch (error) {
      console.error('Error al crear el cliente:', error);
    }
  };

  return (
    <div>
      <h2>Crear Nuevo Cliente</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nombre:</label> {/* Nuevo campo para el nombre */}
          <input
            type="text"
            value={nombre}
            onChange={handleNombreChange}
            required
          />
        </div>
        <div>
          <label>RUT:</label>
          <input
            type="text"
            value={rut}
            onChange={handleRutChange}
            required
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
          Crear Cliente
        </button>
      </form>
    </div>
  );
};

export default CrearCliente;