import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import './Header.css';

function Header() {
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState('opacity-100');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY) {
        setHeaderOpacity('opacity-50');
      } else {
        setHeaderOpacity('opacity-100');
      }
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    const checkSession = async () => {
      const session = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);
    });

    return () => {
      if (authListener) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const handleMouseEnter = () => {
    setHeaderOpacity('opacity-100');
  };

  const handleMenuClick = () => {
    setHeaderOpacity('opacity-100');
  };

  const handleCloseDrawer = () => {
    const drawerToggle = document.getElementById('my-drawer-3');
    if (drawerToggle) {
      drawerToggle.checked = false;
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    navigate('/'); // Redirigir a la página principal después de cerrar sesión
  };

  const handleAccountClick = () => {
    navigate('/mis-datos');
  };

  return (
    <div
      className={`drawer drawer-end fixed top-0 left-0 right-0 z-50 background-header ${headerOpacity}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > prevScrollY) {
          setHeaderOpacity('opacity-50');
        } else {
          setHeaderOpacity('opacity-100');
        }
      }}
    >
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="w-full navbar z-10 flex justify-between items-center">
          <div className="flex items-center justify-start px-2 mx-2">
            <Link to="/" onClick={handleCloseDrawer} className="text-xl normal-case animate-slideDown">
              <img className="logo" src="/img/logo-tubim-small.png" alt="Logo PointCloud" />
            </Link>
          </div>
          <div className="flex-none lg:hidden">
            <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost" onClick={handleMenuClick}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </label>
          </div>
          <div className="hidden lg:flex items-center space-x-4 mr-30">
            {isAuthenticated ? (
              <>
            <Link to="/clientes" onClick={handleCloseDrawer} className={location.pathname === '/clientes' ? 'active hover-underline' : 'hover-underline'}>Clientes</Link>
            <Link to="/proyectos" onClick={handleCloseDrawer} className={location.pathname === '/proyectos' ? 'active hover-underline' : 'hover-underline'}>Proyectos</Link>
            <Link to="/reportes" onClick={handleCloseDrawer} className={location.pathname === '/reportes' ? 'active hover-underline' : 'hover-underline'}>Reportes</Link>
            {/* <Link to="/colaboradores" onClick={handleCloseDrawer} className={location.pathname === '/colaboradores' ? 'active hover-underline' : 'hover-underline'}>Colaboradores</Link> */}
              <div className="relative dropdown dropdown-hover">
                <div tabIndex="0" role="button" className="btn m-1" onClick={handleAccountClick}>
                  <img className="btn-settings" src="/img/settings-blue.png" alt="" />
                </div>
                <ul tabIndex="0" className="dropdown-content menu bg-base-100 rounded-box z-50 w-52 p-2 shadow absolute right-0">
                  <li><Link to="/mis-datos" onClick={handleCloseDrawer}>Mis Datos</Link></li>
                  <li><button onClick={handleLogout}>Salir</button></li>
                </ul>
              </div>
              </>
            ) : (
              <>
                {/* <Link to="/registrate" onClick={handleCloseDrawer} className={location.pathname === '/registrate' ? 'active hover-underline' : 'hover-underline'}>Regístrate</Link> */}
                <Link 
                  to="/ingresar" 
                  onClick={() => navigate('/ingresar', { state: { from: location.pathname } })} 
                  className={location.pathname === '/ingresar' ? 'active hover-underline' : 'hover-underline'}
                >
                  Ingresar
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="drawer-side z-1000">
        <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu p-4 w-60 min-h-full background-mm">          
          {isAuthenticated ? (
            <>
              <li><Link to="/clientes" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/clientes' ? 'active hover-underline' : 'hover-underline'}`}>Clientes</Link></li>
              <li><Link to="/proyectos" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/proyectos' ? 'active hover-underline' : 'hover-underline'}`}>Proyectos</Link></li>
              <li><Link to="/reportes" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/reportes' ? 'active hover-underline' : 'hover-underline'}`}>Reportes</Link></li>
              <li className="mt-auto text-left mt-4 ml-4"><button onClick={handleLogout}>Salir</button></li>
            </>
          ) : (
            <>
              {/* <li><Link to="/" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/' ? 'active hover-underline' : 'hover-underline'}`}>Inicio</Link></li>
              <li><Link to="/productos" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/productos' ? 'active hover-underline' : 'hover-underline'}`}>Productos</Link></li>
              <li><Link to="/como-participar" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/como-participar' ? 'active hover-underline' : 'hover-underline'}`}>Cómo Participar</Link></li> */}
              <li className="mt-auto">
                <Link to="/registrate" onClick={handleCloseDrawer} className="btn-ghost-mm mt-5 mb-2">Registro</Link>
                <Link 
                  to="/ingresar" 
                  onClick={handleCloseDrawer} 
                  className={`btn btn-neutral ${location.pathname === '/ingresar' ? 'active hover-underline' : 'hover-underline'}`}
                >
                  Ingresar
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Header;
