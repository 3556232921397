import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const Cliente = () => {
  const { clienteRut } = useParams();
  const [cliente, setCliente] = useState(null);
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);
  const [invitaciones, setInvitaciones] = useState([]);
  const navigate = useNavigate();
  const { usuario } = useUsuario(); // Obtener el usuario desde el contexto

  useEffect(() => {
    const fetchClienteAndProyectos = async () => {
      try {
        const { data: clienteData, error: clienteError } = await supabase
          .from('clientes')
          .select('*')
          .eq('rut', clienteRut)
          .single();

        if (clienteError) {
          setError(`Error fetching cliente: ${clienteError.message}`);
          setLoading(false);
          return;
        }

        setCliente(clienteData);

        const { data: proyectosData, error: proyectosError } = await supabase
          .from('proyectos')
          .select(`
            *,
            reportes (
              id
            )
          `)
          .eq('cliente_rut', clienteRut);

        if (proyectosError) {
          setError(`Error fetching proyectos: ${proyectosError.message}`);
          setLoading(false);
          return;
        }

        const proyectosWithDetails = await Promise.all(
          proyectosData.map(async (proyecto) => {
            const { data: colaboradoresData, error: colaboradoresError } = await supabase
              .from('colaboradores')
              .select('usuario_id, perfil')
              .eq('cliente_rut', clienteRut);

            if (colaboradoresError) {
              throw new Error(`Error fetching colaboradores: ${colaboradoresError.message}`);
            }

            const colaboradoresWithDetails = await Promise.all(
              colaboradoresData.map(async (colaborador) => {
                const { data: usuarioData, error: usuarioError } = await supabase
                  .from('usuarios')
                  .select('nombre, apellido')
                  .eq('user_id', colaborador.usuario_id)
                  .single();

                if (usuarioError) {
                  throw new Error(`Error fetching usuario: ${usuarioError.message}`);
                }

                return {
                  ...colaborador,
                  nombre: usuarioData.nombre,
                  apellido: usuarioData.apellido,
                };
              })
            );

            return {
              ...proyecto,
              reportCount: proyecto.reportes.length,
              colaboradores: colaboradoresWithDetails, // Añadiendo colaboradores con detalles al proyecto
            };
          })
        );

        setProyectos(proyectosWithDetails);

        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('usuario_id, perfil')
          .eq('cliente_rut', clienteRut);

        if (colaboradoresError) {
          throw new Error(`Error fetching colaboradores: ${colaboradoresError.message}`);
        }

        const colaboradoresWithDetails = await Promise.all(
          colaboradoresData.map(async (colaborador) => {
            const { data: usuarioData, error: usuarioError } = await supabase
              .from('usuarios')
              .select('nombre, apellido')
              .eq('user_id', colaborador.usuario_id)
              .single();

            if (usuarioError) {
              throw new Error(`Error fetching usuario: ${usuarioError.message}`);
            }

            return {
              ...colaborador,
              nombre: usuarioData.nombre,
              apellido: usuarioData.apellido,
            };
          })
        );

        setColaboradores(colaboradoresWithDetails);

        // Obtener las invitaciones basadas en el cliente actual
        const { data: invitacionesData, error: invitacionesError } = await supabase
          .from('invitados')
          .select('email, estado_invitacion, created_at')
          .eq('cliente_rut', clienteRut);

        if (invitacionesError) {
          throw new Error(`Error fetching invitaciones: ${invitacionesError.message}`);
        }

        setInvitaciones(invitacionesData);
      } catch (error) {
        setError(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (usuario && usuario.id) { // Verificación añadida
      fetchClienteAndProyectos();
    }
  }, [clienteRut, usuario]); // Añadir 'usuario' a la lista de dependencias

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Elimina el logo existente si hay uno
    if (cliente && cliente.logo) {
      const url = new URL(cliente.logo);
      const pathArray = url.pathname.split('/');
      const oldPath = pathArray.slice(pathArray.indexOf('public') + 1).join('/');

      try {
        await supabase.storage
          .from('logos')
          .remove([oldPath]);
        console.log("Logo anterior eliminado");
      } catch (error) {
        console.error('Error al eliminar el logo anterior:', error.message);
      }
    }

    // Procede con la carga del nuevo logo
    const fileExtension = file.name.split('.').pop();
    const fileName = `cliente-${clienteRut}-${Date.now()}.${fileExtension}`;
    const newFilePath = `public/${clienteRut}/${fileName}`;

    try {
      let { error: uploadError } = await supabase.storage
        .from('logos')
        .upload(newFilePath, file);

      if (uploadError) {
        throw new Error(`Error al subir el nuevo logo: ${uploadError.message}`);
      }

      const publicURL = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/${newFilePath}`;
      let { error: updateError } = await supabase
        .from('clientes')
        .update({ logo: publicURL })
        .eq('rut', clienteRut);

      if (updateError) {
        throw new Error(`Error al actualizar la referencia del logo: ${updateError.message}`);
      }

      setCliente((prevCliente) => ({ ...prevCliente, logo: publicURL }));
      console.log('Referencia del logo actualizada con éxito en la base de datos.');
    } catch (error) {
      console.error('Error al subir y actualizar el logo del cliente:', error.message);
      setError('Error handling file upload.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      {cliente && (
        <div className="mb-6 flex justify-center items-center">
          <div className="text-center mr-4">
            {cliente.logo ? (
              <img src={cliente.logo} alt="Logo" className="w-24 h-24 object-contain" />
            ) : (
              <p 
                className="text-blue-500 hover:underline cursor-pointer"
                onClick={() => document.getElementById('fileInput').click()}
              >
                Cargar logo
              </p>
            )}
            <input 
              type="file" 
              id="fileInput" 
              style={{ display: 'none' }} 
              onChange={(e) => handleFileUpload(e)} 
            />
            <h1 className="text-3xl font-bold">{cliente.nombre}</h1>
            <p><strong>RUT:</strong> {cliente.rut}</p>
            <p><strong>Dirección:</strong> {cliente.direccion}</p>
            <p><strong>Celular:</strong> {cliente.celular}</p>
            <Link to={`/cliente/${clienteRut}/editar`} className="text-blue-500 hover:underline">Editar</Link>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">Proyectos</h1>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded ml-auto"
          onClick={() => navigate(`/cliente/${clienteRut}/crear-proyecto`)}
        >
          Nuevo Proyecto
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {proyectos.map((proyecto) => (
          <div key={proyecto.id} className="card bg-base-100 w-96 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">{proyecto.proyecto}</h2>
              <p>{proyecto.descripcion}</p>
              <p><strong>Fecha de Inicio:</strong> {proyecto.fecha_inicio}</p>
              <p><strong>Fecha de Término:</strong> {proyecto.fecha_termino}</p>
              <p><strong>Cantidad de Reportes:</strong> {proyecto.reportCount}</p>
              <div className="card-actions justify-end">
                <Link to={`/cliente/${clienteRut}/proyecto/${proyecto.key}`} className="btn btn-primary">
                  Ver Detalles
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-bold">Colaboradores</h1>
        <ul>
          {colaboradores.map((colaborador, index) => (
            <li key={index}>
              {colaborador.nombre} {colaborador.apellido} - {colaborador.perfil}
            </li>
          ))}
        </ul>
        <button 
          className="bg-green-500 text-white px-4 py-2 rounded mt-4"
          onClick={() => navigate(`/cliente/${clienteRut}/crear-colaborador`)}
        >
          Agregar Colaborador
        </button>
        <h2 className="text-2xl font-bold mt-8">Invitaciones</h2>
        <ul>
          {invitaciones.map((invitacion, index) => (
            <li key={index}>
              {invitacion.email} - {invitacion.estado_invitacion} - {new Date(invitacion.created_at).toLocaleDateString()}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Cliente;
