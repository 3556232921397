import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const Reportes = () => {
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // Estado para el orden
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const { user } = useUsuario(); // Obtener el usuario desde el contexto

  useEffect(() => {
    const fetchReportes = async () => {
      try {
        // Obtener los clientes a los que el usuario tiene acceso
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('cliente_rut')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const clienteRuts = colaboradoresData.map(colaborador => colaborador.cliente_rut);

        // Obtener los proyectos de los clientes a los que el usuario tiene acceso
        const { data: proyectosData, error: proyectosError } = await supabase
          .from('proyectos')
          .select('key, cliente_rut, proyecto')
          .in('cliente_rut', clienteRuts);

        if (proyectosError) {
          setError(proyectosError.message);
          setLoading(false);
          return;
        }

        const proyectoKeys = proyectosData.map(proyecto => proyecto.key);

        // Obtener los reportes de los proyectos a los que el usuario tiene acceso
        const { data: reportesData, error: reportesError } = await supabase
          .from('reportes')
          .select('*')
          .in('proyecto_key', proyectoKeys);

        if (reportesError) {
          throw reportesError;
        }

        const promises = reportesData.map(async (reporte) => {
          const proyectoData = proyectosData.find(proyecto => proyecto.key === reporte.proyecto_key);

          const { data: clienteData, error: clienteError } = await supabase
            .from('clientes')
            .select('nombre, rut')
            .eq('rut', proyectoData.cliente_rut)
            .single();

          if (clienteError) {
            throw clienteError;
          }

          const { data: linksData, error: linksError } = await supabase
            .from('links')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (linksError) {
            throw linksError;
          }

          const { data: archivosData, error: archivosError } = await supabase
            .from('archivos')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (archivosError) {
            throw archivosError;
          }

          return {
            ...reporte,
            cliente: clienteData,
            proyecto: proyectoData,
            linkCount: linksData.length,
            archivoCount: archivosData.length,
          };
        });

        const reportesWithData = await Promise.all(promises);

        setReportes(reportesWithData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchReportes();
  }, [user.id]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const filteredReportes = reportes.filter((reporte) => {
    return (
      reporte.reporte.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reporte.cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reporte.proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortedReportes = [...filteredReportes].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.linkCount - b.linkCount || a.archivoCount - b.archivoCount;
    } else {
      return b.linkCount - a.linkCount || b.archivoCount - a.archivoCount;
    }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold text-center mb-4">Lista de Reportes</h1>
      <input
        type="text"
        placeholder="Buscar por reporte, cliente o proyecto"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="input input-bordered mb-4 w-full"
      />
      <button onClick={toggleSortOrder} className="btn btn-secondary mb-4">
        Ordenar por {sortOrder === 'asc' ? 'Descendente' : 'Ascendente'}
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sortedReportes.map((reporte) => (
          <div key={reporte.id} className="card bg-base-100 w-96 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">{reporte.reporte}</h2>
              <p><strong>Proyecto:</strong> {reporte.proyecto.proyecto}</p>
              <p><strong>Cliente:</strong> {reporte.cliente.nombre}</p>
              <p><strong>Cantidad de Links:</strong> {reporte.linkCount}</p>
              <p><strong>Cantidad de Archivos:</strong> {reporte.archivoCount}</p>
              <div className="card-actions justify-end">
                <Link to={`/cliente/${reporte.cliente.rut}/proyecto/${reporte.proyecto.key}/reporte/${reporte.key}`} className="btn btn-primary">
                  Ver Detalles
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reportes;
